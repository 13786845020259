.footer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #FF4387;
    padding-left: 30px;
    min-height: 300px;
    height: fit-content;
    color: white;
    padding-top: 50px;
    padding-bottom: 50px;
}

.button {
    color: #fff;
    font-weight: 600;
    background-color: #de1b62;
    box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.35);
    margin-top: 10px;
    font-size: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    
}

.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navlinks{
    padding-top: 30px;
    list-style-type: none;
}

.links{
    margin-bottom: 10px;
}

.links:hover{
    text-decoration: underline;
    cursor: pointer;
}


.a{
    text-decoration: none;
    color: white;
    font-weight: bold;
    
}

.left{
    display: flex;
    gap: 40px;
}

.address {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.address p {
    max-width: 350px;
}

@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 30px;
    }

    .left {
        display: flex;
        flex-direction: column;
    }
}