/* VideoGallery.module.css */
.video-gallery-container {
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
}

.main-video-container {
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.main-video-container iframe {
  width: 100%;
  height: 100%;
}

.video-row-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.small-video {
  width: calc(33.333% - 10px); /* Adjust the width as needed */
  height: 200px;
  cursor: pointer;
}

.small-video iframe {
  width: 100%;
  height: 100%;
}
