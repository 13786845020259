.container{
    display: flex;
    align-items: center;
    border-radius: 25px;
    box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.2);
    width: 90%;
    margin: 0 auto;
    padding: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.iconDiv{
    color: #FF4387;
}
.cardContent h1{
    font-size: 25px;
}

.cardContent p{
    font-size: 18px;
}

.iconDiv img{
    width: 150px;
    padding-right: 20px;
    padding-left: 10px;
}

@media screen and (max-width: 768px) {
    
    .container{
        flex-direction: column;
        text-align: center;
        height: fit-content;
        flex: 0 0 100%;
    }

    .iconDiv {
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .cardContent{
        padding-bottom: 30px;
    }

    .cardContent h1{
        padding-bottom: 15px;
    }
}

.cardContent p {
    font-size: 16px;
    color: #3a3c3d;
    margin-top: 7px;
    padding-right: 10px;
    text-align: justify;
}