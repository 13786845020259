.navbar {
    background-color: #FF4387;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    min-height: 100px;
}

.button {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    background-color: #de1b62;
    box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.35);
    padding: 15px 30px;
    border-radius: 20px;
    border: none;
    
}

.logo img{
    max-height: 90px;
}

.navlinks {
    list-style: none;
    display: flex;
    align-items: center;
}

.links {
    margin-right: 20px;
    
}

.links:hover{
    cursor: pointer;
    padding: 5px;
    background-color: #de1b62;
    border-radius: 10px;
    box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.2);
}

.a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.burger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.line {
    width: 30px;
    height: 3px;
    background-color: #fff;
    margin: 3px 0;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    .navlinks {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;
        background-color: #FF4387;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        padding: 10px 30px;
    }

    .active {
        display: flex;
    }

    .burger {
        display: flex;
    }

    .button {
        color: #fff;
        font-weight: 600;
        background-color: #de1b62;
        border-radius: 20px;
        border: none;
    }

    .logo img{
        width: 200px;
    }
}